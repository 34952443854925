import * as yup from 'yup';

export const MembershipFormSchema = yup.object().shape({
  product: yup
    .object()
    .shape({
      productId: yup.string().required(),
      amount: yup.string().required(),
      isHouseholdProduct: yup.boolean().required(),
    })
    .required('Du må velge et produkt!'),
});
